<template>
  <a-card :bordered="false">
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="编号" v-if="false">
              <a-input disabled v-decorator="['code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" allow-clear/>
            </a-form-item>
            <a-form-item label="名称">
              <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" allow-clear/>
            </a-form-item>
            <a-form-item label="发货人电话">
              <a-input v-decorator="['send_mobile', {rules: [{required: false, message: '必填项，请填写信息'}, {max:20, message: '输入超出长度限制'}]}]" allow-clear/>
            </a-form-item>
            <a-form-item label="发货人">
              <a-input v-decorator="['sender_name', {rules: [{required: false, message: '必填项，请填写信息'}, {max:8, message: '输入超出长度限制'}]}]" allow-clear/>
            </a-form-item>
            <a-form-item label="位置" v-if="false">
              <a-input v-decorator="['location', {rules: [{required: false, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" allow-clear/>
              <a-select
                v-if="false"
                v-decorator="[
                  'location',
                  {
                    rules: [
                      { required: false, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.CorporationLocation">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="仓库所在省份">
              <a-input v-decorator="['province', {rules: [{required: true, message: '必填项，请填写信息'}, {max:40, message: '输入超出长度限制'}]}]" allow-clear/>
            </a-form-item>
            <a-form-item label="仓库所在市">
              <a-input v-decorator="['city', {rules: [{required: true, message: '必填项，请填写信息'}, {max:40, message: '输入超出长度限制'}]}]" allow-clear/>
            </a-form-item>
            <a-form-item label="仓库所在区">
              <a-input v-decorator="['county', {rules: [{required: true, message: '必填项，请填写信息'}, {max:40, message: '输入超出长度限制'}]}]" allow-clear/>
            </a-form-item>
            <a-form-item label="仓库具体地址">
              <a-input v-decorator="['address', {rules: [{required: true, message: '必填项，请填写信息'}, {max:100, message: '输入超出长度限制'}]}]" allow-clear/>
            </a-form-item>
            <a-form-item label="商品类型" v-if="false">
              <a-select
                v-decorator="[
                  'goods_type',
                  {
                    rules: [
                      { required: false, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.GoodsType">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="面积(平方米)" v-if="false">
              <a-input v-decorator="['area', {rules: [{required: true, message: '必填项，请填写信息'}]}]" allow-clear/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item
          v-bind="buttonCol"
        >
          <a-row>
            <a-col :span="8"></a-col>
            <a-col :span="6">
              <a-button type="primary" @click="handleSubmit">提交</a-button>
            </a-col>
            <a-col :span="6" :offset="1">
              <a-button @click="handleGoBack">返回</a-button>
            </a-col>
          </a-row>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-card>
</template>

<script>
import pick from 'lodash.pick'
import { warehouse_update, warehouse_list } from '@/api/c_wms_warehouse'
// 表单字段
const fields = ['id', 'name', 'code', 'send_mobile', 'goods_type', 'sender_name', 'province', 'city', 'county', 'address']
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      },
      form: this.$form.createForm(this),
      loading: false,
      id: 0,
      location: '',
      wms_warehouses: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      fields.forEach(v => this.form.getFieldDecorator(v))
      this.loading = true
      warehouse_list({ id: this.$route.params.id })
        .then(({ data }) => {
          const entries = data.entries
          if (entries && entries.length > 0) {
            this.loadEditInfo(entries[0])
          }
        }).finally(() => {
          this.loading = false
      })
    })
  },
  methods: {
    handleGoBack () {
      this.$router.go(-1)
    },
    handleSubmit () {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('values:', values)
          warehouse_update(values, this.id).then((res) => {
            this.handleGoBack()
          })
        }
      })
    },
    loadEditInfo (data) {
      const { form } = this
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, fields)
        this.id = formData.id
        formData.goods_type = data.goods_type.toString()
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
